<template>
  <div class="print-lending">
    <!-- heading -->
    <div class="heading">
      <div class="heading-left">
        <inline-svg
          src="media/svg/logo/logo-ldn.svg"
          width="160"
          height="160"
        ></inline-svg>
      </div>
      <div class="heading-right">
        <div class="heading-right__content">
          <h2>CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</h2>
          <p class="text-bold">Độc lập - Tự do - Hạnh phúc</p>
        </div>
      </div>
    </div>
    <!-- body -->
    <div class="body">
      <div class="report-confirm">
        <h2>BIÊN BẢN XÁC NHẬN CHO KHÁCH HÀNG MƯỢN MÁY KHI BẢO HÀNH</h2>
        <p class="normal-mt">Căn cứ vào nhu cầu và khả năng của hai bên</p>
        <p>Hôm nay, ngày {{printModel.date}} tháng {{ printModel.month }} năm {{ printModel.year }}, chúng tôi gồm:</p>
      </div>
      <div class="report-shop normal-mt">
        <h2>BÊN BÀN GIAO (BÊN A): CÔNG YT TNHH ĐẦU TƯ NĂNG LƯỢNG XANH VE VIỆT NAM</h2>
        <div class="report-shop-container normal-mt">
          <div class="shop-item">
            <p>Đại diện Bên giao</p>
          </div>
          <div class="shop-item info">: <p class="underline">{{ printModel.storeName}}</p>
          </div>
          <div class="shop-item">
            <p>Chức vụ</p>
          </div>
          <div class="shop-item info">: <p class="underline"></p>
          </div>
          <div class="shop-item">
            <p>Địa chỉ</p>
          </div>
          <div class="shop-item info">: <p class="underline">{{ printModel.storeAddress }}</p>
          </div>
        </div>
      </div>
      <div class="report-customer normal-mt">
        <h2>BÊN NHẬN BÀN GIAO (BÊN B):</h2>
        <div class="report-customer-container normal-mt">
          <div class="item">Đại diện Bên nhận</div>
          <div class="item info">: <p class="underline"></p>
          </div>
          <div class="item">Điện thoại</div>
          <div class="item info">: <p class="underline"></p>
          </div>
          <div class="item">Địa chỉ</div>
          <div class="item info">: <p class="underline"></p>
          </div>
          <div class="item">CCCD</div>
          <div class="item customer-extra">
            <div>:</div>
            <div
              style="margin-left: 6px"
              class="underline width-80 margin-0"
            >
              <p></p>
            </div>
            <div>
              <p>Ngày cấp:</p>
            </div>
            <div class="underline width-80 margin-0">
              <p></p>
            </div>
            <div>Nơi cấp: </div>
            <div class="underline margin-0">
              <p></p>
            </div>
          </div>
        </div>
      </div>
      <p class="normal-mt">Hai bên tiến hành giao nhận máy cho mượn trong thời gian gửi máy bảo hành theo nội dung sau:</p>
      <div class="product-list normal-mt">
        <table>
          <thead>
            <tr>
              <th class="th-stt">STT</th>
              <th class="th-name">Tên thiết bị</th>
              <th class="th-imei-code">Số seri/ imei máy</th>
              <th class="th-quantity">Số lượng</th>
              <th class="th-status">
                <p>Hiện trạng</p>
                <p>(tình trạng ghi rõ)</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(product,index) in printModel.products">
              <tr v-bind:key="index">
                <td class="td-stt">{{ index + 1 }}</td>
                <td class="td-name">{{ product.name }}</td>
                <td class="td-imei-code">{{ product.IMEI }}</td>
                <td class="td-quantity">{{ product.quantity }}</td>
                <td class="td-status"></td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <p>Hai Bên đã kiểm tra thông tin và hiện trạng máy cho mượn trong thời gian
        gửi máy bảo hành, sau khi bàn giao Bên B hoàn toàn chịu trách nhiệm về sản
        phẩm trên</p>

      <div class="warranty-condition">
        <h3 class="normal-mt">Các điều kiện cho mượn máy trong thời gian Bên B gửi máy bảo hành như sau:</h3>
        <ul class="normal-mt">
          <li class="menu-item">
            <p>Máy bên A cho bên B mượn không vượt quá 50% giá trị mà Bên B gửi bảo hành.</p>
          </li>
          <li class="menu-item">
            <p>Bên A thu hồi lại máy cho mượn trong vòng 30 (ba mươi) ngày.</p>
          </li>
          <li class="menu-item">
            <p>Sau 30 (ba mươi) ngày bên A cho bên B mượn máy, nếu Bên B không trả máy đã mượn thì
              đồng nghĩa với việc Bên B đồng ý từ bỏ quyền sở hữu và Bên A sẽ mua lại máy mà bên B
              đã gửi Bên A bảo hành.</p>
          </li>
          <li class="menu-item">
            <p> Bên B có nghĩa vụ bảo quản máy mượn đúng với hiện trạng khi nhận máy. Theo đó, Bên A
              được quyền từ chối nhận lại nếu máy bị hư hỏng và Bên B có trách nhiệm thanh toán chi phí
              sửa chữa hoặc mua lại máy mượn bị hư tuỳ vào thoả thuận giữa 2 Bên.</p>
            <p>Hai bên thống nhất lập Biên bản bàn giao theo giữa nội dung nếu trên. </p>
            <p>Biên bản bàn giao máy này được lập thành 02 (hai) bản giống nhau, mỗi bên giữ 01 (một)
              bản có giá trị tương đương nhau</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- sign -->
    <div class="sign">
      <div class="sign-master">
        <span>ĐẠI DIỆN BÊN A</span>
      </div>
      <div class="sign-customer">
        <span>ĐẠI DIỆN BÊN B</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    printModel: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="css" scoped>
/* Common */
* {
  --primary-color: #000000;
  color: var(--primary-color);
  font-size: 18px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.print-lending {
  padding: 40px 60px;
}

.font-size-large {
  font-size: 26px;
  color: var(--primary-color);
}

.text-bold {
  font-weight: 500;
}

.heading {
  height: 160px;
  display: flex;
  flex-direction: row;
}

.heading-left,
.heading-right {
  display: flex;
}

.heading-left {
  justify-content: left;
}

.heading-right {
  margin: auto;
  text-align: center;
}

.heading-right__content {
  width: 100%;
}

.heading-right__content h2 {
  font-size: 24px;
}

.heading-right__content p {
  text-decoration: underline;
}

.report-confirm {
  margin-top: 20px;
}

.report-confirm p:nth-of-type(1) {
  font-style: italic;
}

.underline {
  border-bottom: 1px solid black;
  margin-left: 10px;
  display: inline-block;
  width: 100%;
}

.normal-mt {
  margin-top: 14px;
}

.report-shop-container,
.report-customer-container {
  display: grid;
  grid-template-columns: 15% auto;
  grid-gap: 10px;
}

.report-shop-container .info {
  display: flex;
}

.report-customer-container .info {
  display: flex;
}

/* .underline:not(:empty) {
  border-bottom: none;
} */

.customer-extra {
  display: grid;
  grid-template-columns: 1% 20% 80px 20% 66px auto;
}

.width-80 {
  width: 96%;
}

.margin-0 {
  margin: 0;
}

.report-shop__left,
.report-customer__left {
  width: 180px;
}

.report-shop__right,
.report-customer__right {
  width: 100%;
}

.report-shop__right p,
.report-customer__right p {
  border-bottom: 1px solid black;
  margin-left: 12px;
}

table {
  width: 100%;
}

table th {
  text-align: center;
}

table,
th,
td {
  border: 1px solid black;
  padding: 8px;
}
.th-quantity {
  width: 10%;
}
.th-status {
  width: 20%;
}
.th-status p {
  margin-bottom: 0;
}
.td-stt {
  text-align: center;
}
.td-imei-code {
  text-align: center;
}
.td-quantity {
  text-align: center;
}
ul {
  list-style-position: inside;
}
ul li {
  list-style-type: decimal;
}
ul li p {
  display: inline;
}
li.menu-item:not(:last-child) {
  margin-bottom: 10px;
}
.sign {
  display: flex;
  height: 100px;
  margin-top: 20px;
  flex-direction: row;
  justify-content: space-around;
  font-weight: 500;
}

.sign-master::after,
.sign-customer::after {
  content: '(Ký và ghi rõ họ tên)';
  display: inherit;
  font-style: italic;
  font-size: 16px;
}
</style>